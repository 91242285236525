.dynamic-table {
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .dynamic-table {
    overflow-y: scroll;
  }
}

.dynamic-table table {
  width: 90%;
  margin: auto;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 30px 0px;
}

.dynamic-table table .cell {
  display: flex;
  justify-content: baseline;
  align-items: center;
  padding: 10px 0px;
}

@media only screen and (max-width: 768px) {
  .dynamic-table table .cell {
    padding: 10px;
  }
}

.dynamic-table table .cell img {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  margin-right: 12px;
}

.dynamic-table .collection-name a {
  font-size: 14px;
  font-weight: bold;
  color: #585559;
  text-decoration: none;
}

.dynamic-table .collection-stats {
  font-size: 14px;
  font-weight: bold;
  color: #9b989c;
}

.dynamic-table .collection-stats span {
  font-size: 10px;
}
