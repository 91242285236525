html {
  background-color: #ffffff;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  margin-bottom: 65px;
}

h1 .thin {
  font-weight: 300;
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 42px;
  margin-bottom: 32px;
  color: #d35b79;
  margin-top: 70px;
  text-align: center;
}
h3 {
  font-size: normal;
  font-weight: bold;
  font-size: 22px;
  color: #595959;
  text-transform: uppercase;
  line-height: 28px;
  margin-bottom: 25px;
}

/* Banner */
.banner{
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center; 
  position: relative;
  grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
  background: #EAF2F8;
  border-radius: 10px;
}
.banner .logo-box a{
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}
.banner .title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #090909;
  margin-bottom: 15px;
}
.banner .info{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin-left: 15px;
  background-color: transparent;
  text-align: left;
}
.banner .info:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}
.banner .domaner-btn{
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
  .banner .title{
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 10px;
  }
  .banner .info{
      font-size: 18px;
  }
  .banner .domaner-btn{
      font-size: 18px;
  }
  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}
@media only screen and (max-width: 940px) {
  .banner{
      grid-template-columns: 1fr;
      padding: 10px;
  }
  .banner .logo-box a{
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }
  .banner .title{
      line-height: 24px;
      text-align: center;
  }
  .banner .info{
      font-size: 16px;
      line-height: 20px;
      text-align: center;
  }
  .banner .info:before{
      content: none;
  }
}
@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}


.header-description {
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 25px;
}
.more {
  display: block;
  transform: scaleY(0);
  height: 0;
  transition: transform 0.12s ease;
  margin-top: 5px;
}
.site-header-inner.show-more .more {
  display: block;
  transform: scaleY(1);
  height: 2em;
  transition: transform 0.12s ease;
  margin-top: 0;
}
.site-header-inner.show-more .dots {
  display: none;
}
.site-header-inner.show-more .cta-btns {
  margin-top: 40px;
}
.click-for-more {
  height: 16px;
  width: 16px;
  display: inline-block;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
  background-image: url("../image/more-text.png");
  cursor: pointer;
}
.show-more-btn {
  margin-top: 15px !important;
}
p {
  text-align: center;
}
p:last-child {
  margin-bottom: 0;
}
section p {
  font-size: 15px;
  color: #6f6c70;
  line-height: 26px;
  margin-bottom: 25px;
}
section p:last-child {
  margin-bottom: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #d35b79;
}
.scroll-to-link * {
  pointer-events: none;
}

.site-logo {
  font-size: 18px;
  color: transparent;
  background: url(../image/logo.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.logo-wrapper {
  display: flex;
  height: 161px;
  justify-content: center;
  padding-bottom: 22px;
  padding-top: 100px;
  position: relative;
  z-index: 1;
  width: 220px;
  margin: 0 auto 50px;
}
.site-header {
  background-color: #d35b79;
  color: #fff;
  overflow: hidden;
  position: relative;
}
.site-header-inner {
  position: relative;
  padding: 0 60px 105px;
  text-align: center;
}

.header-image {
  position: absolute;
  right: 0;
  bottom: 0;
}

.header-description {
  margin-bottom: 60px;
  width: 487px;
  margin: 0 auto;
}
.intro{
  width: 50%;
  margin: 0 auto;
}
.nav-wrapper {
  font-size: 17px;
  font-weight: 700;
  margin: 0 auto;
  position: relative;
  width: 915px;
}
.nav-wrapper-bg {
  background-color: #fff;
  border-radius: 69px;
  bottom: 0;
  left: 0;
  opacity: 0.15;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.nav-wrapper ul {
  display: flex;
  justify-content: space-around;
}

.nav-wrapper li {
  margin-right: 31px;
}

.nav-wrapper a {
  align-items: center;
  background-position: 0;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  position: relative;
}

.nav-wrapper a:hover {
  color: #000;
  transition: color 0.2s;
}

.cta-btns {
  padding: 15px 50px;
  position: relative;
  z-index: 2;
  margin-top: 90px;
}
.cta-btns span {
  position: relative;
}
.cta-btns li:first-of-type span:before {
  background-image: url("../image/tool.png");
  left: -25px;
  top: 2px;
}
.cta-btns li:nth-of-type(2) span:before {
  background-image: url("../image/features.png");
  left: -25px;
  top: 2px;
}
.cta-btns li:nth-of-type(3) span:before {
  background-image: url("../image/create.png");
  left: -25px;
  top: 6px;
}
.cta-btns li:nth-of-type(4) span:before {
  background-image: url("../image/faqs.png");
  left: -25px;
  top: 2px;
}
.cta-btns li a:hover span:before {
  filter: invert(50%) sepia(94%) saturate(2%) hue-rotate(281deg) brightness(28%)
    contrast(121%);
}

.cta-btns span:before {
  background-repeat: no-repeat;
  content: "";
  height: 25px;
  left: -25px;
  position: absolute;
  top: 2px;
  transition: filter 0.2s ease-in-out;
  width: 25px;
}

.hero-image {
  position: relative;
}

.faq-wrapper-outer::before, .site-header:before {
  content: '';
  background: #F96333;
  filter: blur(100px);
  height: 800px;
  width: 800px;
  border-radius: 50%;
  opacity: .3;
  position: absolute;
  right: 0;
  top: -30px;
}
.bg-image {
  background-image: url("../image/header-bg.webp");
  height: 1800px;
  left: -100px;
  position: absolute;
  width: 50%;
  width: 2082px;
  top: 80px;
}

/* CARDS */
.card-wrapper img {
  margin-bottom: 27px;
}
.card-wrapper {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 50px;
  margin-top: 60px;
}
.card-wrapper p {
  font-size: 14px;
}

.card1,
.card2,
.card3,
.card4,
.card5 {
  box-shadow: 0px 0px 25px 0px #00000014;
  border-radius: 20px;
  padding: 50px 35px;
  margin-bottom: 50px;
}
.card-left-text {
  text-align: left;
}
.card4 h3,
.card5 h3 {
  margin-left: 23px;
}
.features2 h3 {
  position: relative;
}
.features2 h3:before {
  content: "";
  width: 45px;
  height: 45px;
  position: absolute;
  background-color: #ffe6ec;
  border-radius: 50%;
  z-index: -1;
  left: -25px;
  top: -9px;
}
.create-own-avatar {
  margin-bottom: 80px;
}

.our-team {
  background-color: #2b2b2b;
  padding: 1px 0 80px;
}
.our-team p {
  color: #e8e8e8;
}

.faq-wrapper-outer {
  background-color: #d35b79;
  overflow: hidden;
  position: relative;
}
.faq-wrapper {
  position: relative;
  z-index: 2;
}
.faq-section-wrapper {
  padding-bottom: 90px;
}
.faq-heading {
  margin-bottom: 38px !important;
  padding-top: 68px !important;
}
.faq.expanded + .faq {
  border-top: 0;
}

.faq-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.faq-title h3 {
  color: white;
  font-size: 28px;
  font-weight: 400;
  height: 75px;
  line-height: 75px;
  pointer-events: none;
  text-transform: none;
  margin-bottom: 0;
}
.faq-btn {
  align-items: center;
  border: 0;
  border-radius: 25px;
  display: flex;
  font-size: 42px;
  height: 49px;
  justify-content: center;
  margin-right: 42px;
  width: 49px;
}
.faq-btn:hover,
.faq-title:hover {
  cursor: pointer;
}

.faq {
  padding-left: 40px;
  transition: background-color 0.2s linear, font-weight 0.2s linear,
    border-radius 0.2s linear;
  position: relative;
  margin-bottom: 25px;
}
.faq-background-outer {
  background-color: white;
  opacity: 0.3;
  position: absolute;
  border-radius: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.faq-content {
  position: relative;
  z-index: 2;
}

.faq .faq-body {
  color: white;
  font-size: 17px;
  line-height: 24px;
  padding-bottom: 44px;
  border-top: 1px solid white;
  padding-top: 25px;
  width: 95%;
}
.faq.collapsed .faq-body {
  display: none;
}
.faq-body a {
  font-weight: bold;
  color: white;
  text-decoration: underline;
}
.faq-btn span {
  pointer-events: none;
}

.faq.collapsed .faq-btn span {
  color: white;
}

.faq.expanded .faq-btn span {
  color: white;
}

.faq.collapsed .faq-btn span.plus,
.faq.expanded .faq-btn span.minus {
  display: block;
}

.faq.collapsed .faq-btn span.minus,
.faq.expanded .faq-btn span.plus {
  display: none;
}
.faq.expanded .faq-title h3 {
  font-weight: 700;
}
.text-center {
  text-align: center;
  color: white;
}
.text-center p {
  padding-bottom: 50px;
  color: #fff;
}
.text-center-h2 {
  color: white;
}
.faq-title h3 {
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.faq-body p {
  color: white;
  text-align: left;
}
/* ENDS FAQ SECTION  */

.contact-us-outer {
  position: relative;
}
.contact-us-outer-opacity {
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.15;
}
.contact-us-inner {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 1px 20px 70px;
}
.contact-us-inner h2 {
  color: white;
  font-weight: 700;
}
.contact-us-inner p {
  color: white;
  font-size: 15px;
  margin-bottom: 50px;
}
.contact-link {
  text-decoration: underline;
  color: white;
  font-weight: bold;
}
.contact-btn {
  background: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 17px 53px;
  border-radius: 69px;
  box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
  color: #d35b79;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}
.contact-btn:hover{
  background: #57F1F4;
  color: #D35B79;
}

footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f8b4c5;
}
footer {
  background-color: #da738d;
}
footer .copyright {
  color: #fff;
  font-size: 14px;
  padding: 12px 0 12px;
}

.m-visible {
  display: none;
}

.m-hidden {
  display: initial;
}

@media only screen and (max-width: 992px) {
  .intro{
    width: 100%;
    margin-bottom: 30px;
  }
  .cta-btns {
    margin-top: 0px;
  }

  .cta-btns li:first-of-type a:before {
    left: 143px;
    top: 23px;
  }

  .cta-btns li:nth-of-type(2) a:before {
    left: -25px;
    top: 2px;
  }

  .cta-btns li:nth-of-type(3) a:before {
    left: -25px;
    top: 6px;
  }

  .cta-btns li:nth-of-type(3) span:before {
    left: -26px;
  }

  .cta-btns li:nth-of-type(4) a:before {
    left: -25px;
    top: 2px;
  }

  .btn-lis a {
    justify-content: center;
    padding: 20px;
    position: relative;
  }

  .cta-btns {
    padding: 0;
  }

  .transparent-bg {
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.15;
    border-radius: 69px;
  }

  .nav-wrapper p {
    margin-bottom: 20px;
  }

  .nav-wrapper ul {
    display: block;
  }

  .nav-wrapper ul li:not(:last-child) {
    margin-bottom: 16px;
  }

  .nav-wrapper li {
    width: 100%;
    margin-right: 0px;
  }

  .nav-wrapper {
    width: 100%;
  }

  .nav-wrapper-bg {
    display: none;
  }

  .card-wrapper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) {
  header,
  article,
  footer {
    padding: 0;
  }
  .container {
    padding: 0px 22px;
  }
  h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .mobile-detector {
    display: none;
  }
 
  .header-image {
    display: none;
  }

  .site-header-inner {
    padding: 26px 20px 28px;
  }

  .header-description {
    margin-bottom: 26px;
    width: 100%;
  }
  .show-more-btn {
    margin-top: 40px;
  }
  .logo-wrapper {
    padding-top: 20px;
    height: 90px;
    margin-bottom: 0;
  }
  .contact-btn {
    font-size: 18px;
  }
  /* .contact-btn:hover:before {
    bottom: -11px;
  } */
  .image-wrapper {
    left: -215px;
  }
  .bg-image {
    left: -1000px;
  }

  .bg-gradient-inner {
    left: -275px;
  }

  .nav-wrapper p {
    margin-bottom: 20px;
  }

  .card1,
  .card2,
  .card3,
  .card4,
  .card5 {
    padding-left: 25px;
    padding-right: 25px;
  }

  /* BUTTON FAQ MOBILE SHOW MORE  */
  .intro .m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
  }
  .m-readmore-btn {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 15px auto 50px;
    text-transform: uppercase;
    width: 100%;
  }
  .m-readmore-btn * {
    pointer-events: none;
  }

  footer .container {
    flex-direction: column;
  }

  footer .logo-wrapper {
    padding-bottom: 8px;
  }
  tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .dynamic-table table .cell{
      flex-direction: column;
      text-align: center;
  }
  .dynamic-table table .cell img{
      width: 55px!important;
      height: 55px!important;
      margin-right: 0 !important;
      margin-bottom: 5px;
  }
}

@media only screen and (max-width: 351px) {
  .contact-btn {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .site-header-inner {
    padding-left: 1px;
    padding-right: 1px;
  }
  .container {
    padding: 0 10px;
  }
  .show-more-btn {
    margin-top: 35px !important;
  }
  .card1,
  .card2,
  .card3,
  .card4,
  .card5 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Read more */

.m-slide-down {
  height: 0;
  overflow: hidden;
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
}
.m-hidden, .mobile-detector {
  display: none;
}
.m-slide-down-measure p {
  margin: 0!important;
  padding-top: 20px;
}
.m-readmore-btn{
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-size: 0;
  font-weight: 500;
  color: white;
  width: 100%;
  margin: 15px auto 50px;
}

.m-readmore-btn::before {
  cursor: pointer;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  color: white;
  font-size: 24px!important;
}

.m-readmore-btn.read-more-2::before {
  content: "\002B";
}
.m-readmore-btn.collapse-2::before{
  content: "\2212";
}
.m-readmore-btn.read-more-2:hover::before, .m-readmore-btn.collapse-2:hover::before{
  color: var(--button-primary-color)
}
.m-readmore-btn * {
  pointer-events: none;
}
.intro .m-visible {
  display: initial;
}
.intro .m-hidden, .intro .mobile-detector {
  display: none;
}
.slide-down {
  height: 0;
  overflow: hidden;
  transition: height 0.2s linear;
}
.m-slide-down-measure p {
  padding-top: 20px;
  margin: 0 !important;
}

@media only screen and (max-width: 867px){
  .mobile-detector {
      display: none;
  }

  .m-hidden {
      display: none;
  }

  .m-visible {
      display: initial;
  }

  .m-slide-down {
      -moz-transition: height .5s;
      -ms-transition: height .5s;
      -o-transition: height .5s;
      -webkit-transition: height .5s;
      transition: height .5s;
      height: 0;
      overflow: hidden;
  }
  .col2-1, .col1-3, .col3-1{
      grid-template-columns: 1fr;
  }
}
